let Clients = {
    init: function (conf) {
        this.config = conf
        this.bindEvents()
        this.letterMargins = []
        this.createJumbledText('OUR PRECIOUS')
    },

    bindEvents: function () {
        window.addEventListener('scroll', this.windowScroll)
    },

    changeJumbledLetterPosition: function () {
        const self = Clients
        const currentTopOfWord = self.config.jumbledTitle.getBoundingClientRect().top
        const heightOfWindow = window.innerHeight
        if (currentTopOfWord > heightOfWindow) {
            return
        }

        const lettersElements = document.querySelectorAll('.js-clients-jumbled-title span')
        lettersElements.forEach((el, index) => {
            const topMargin = self.letterMargins[index]
            const percent = ((currentTopOfWord - 2/3 * heightOfWindow) * 100 / (2/3 * heightOfWindow))
            if (percent > 0) {
                const newTopMargin = (percent / 100) * topMargin
                el.style.marginTop = newTopMargin + 'px'
            }
        })
    },

    changeClientsTitleBoxPosition: function () {
        const self = Clients
        const currentTopOfTitleBox = self.config.clientsTitleBox.getBoundingClientRect().top
        const heightOfWindow = window.innerHeight
        if (currentTopOfTitleBox > heightOfWindow) {
            return
        }
        const titleBoxTopMargin = 150
        const titleBoxPercent = 100 - ((currentTopOfTitleBox - 1/2 * heightOfWindow) * 100 / (1/2 * heightOfWindow))
        if (titleBoxPercent <= 100 && titleBoxPercent >= 0) {
            const newTopMargin = (titleBoxPercent / 100) * titleBoxTopMargin
            self.config.clientsTitleBox.style.marginTop = newTopMargin + 'px'
        }
    },

    clientsLogosCarousel: function () {
        const self = Clients
        const clientsLogosBoxOffsetTop = self.config.clientsLogosBox.getBoundingClientRect().top
        if (clientsLogosBoxOffsetTop > window.innerHeight || clientsLogosBoxOffsetTop < 0) {
            return
        }
        const heightOfWindow = window.innerHeight
        self.config.clientsLogos.forEach((el) => {
            el.style.transform = `translate(${-clientsLogosBoxOffsetTop + 'px'}, 0)`
        })
    },

    windowScroll: function () {
        const self = Clients
        self.changeJumbledLetterPosition()
        self.changeClientsTitleBoxPosition()
        self.clientsLogosCarousel()
    },

    createJumbledText: function (text) {
        const self = Clients
        if (!text) {
            return
        }

        const textSplit = text.split('')
        const parentElement = document.createElement('div')
        parentElement.classList.add('inline-flex')
        textSplit.forEach((el, index) => {
            let randomNumber = (Math.random() < 0.5 ? -1 : 1) * (Math.floor(Math.random() * 100))
            if (randomNumber > 50) {
                randomNumber = 50
            } else if (randomNumber < -50) {
                randomNumber = -50
            }
            const letterElement = document.createElement('span')
            letterElement.innerText = el
            if (el === ' ') {
                letterElement.classList.add('px-2')
            }
            if (index === 0) {
                letterElement.style.marginTop = '-50px'
                self.letterMargins[0] = -50
            } else {
                self.letterMargins[index] = randomNumber
                letterElement.style.marginTop = randomNumber + 'px'
            }
            parentElement.appendChild(letterElement)
        })
        self.config.jumbledTitle.appendChild(parentElement)
    }
}

Clients.init({
    jumbledTitle: document.querySelector('.js-clients-jumbled-title'),
    clientsTitleBox: document.querySelector('.js-clients-title-box'),
    clientsLogos: document.querySelectorAll('.js-client-logo'),
    clientsLogosBox: document.querySelector('.js-clients-logos-box'),
})
