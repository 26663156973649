import * as THREE from 'three';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { FontLoader } from 'three/addons/loaders/FontLoader.js';
import monitoricaBoldFont from '../fonts/Monitorica_Bold.json'

const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 0.1, 1000 );
let renderer = new THREE.WebGLRenderer( { antialias: true } );
THREE.ColorManagement.enabled = true; // TODO: Confirm correct color management.

renderer.shadowMap.enabled = true;

let clock;

let refractor;

init();

function init() {

    window.addEventListener('resize', onWindowResize, false)
    window.addEventListener('scroll', changeCameraPosition)
    function onWindowResize() {
        camera.aspect = window.innerWidth / window.innerHeight
        camera.updateProjectionMatrix()
        renderer.setSize(window.innerWidth, window.innerHeight)
        renderer.render(scene, camera)
    }

    const firstView = document.querySelector( '.js-first-view' );

    renderer.setSize(firstView.clientWidth, firstView.clientHeight);

    clock = new THREE.Clock();

    // renderer
    renderer.outputColorSpace = THREE.LinearSRGBColorSpace;
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( window.innerWidth, window.innerHeight );
    firstView.appendChild( renderer.domElement );

    camera.position.set( 200, 400, 200 );

    const controls = new OrbitControls( camera, renderer.domElement );
    if (window.innerWidth < 640) {
        controls.maxDistance = 850;
        controls.minDistance = 850;
    } else if (window.innerWidth < 1300 && window.innerWidth >= 640 ) {
        controls.maxDistance = 750;
        controls.minDistance = 750;
    } else {
        controls.maxDistance = 650;
        controls.minDistance = 650;
    }
    controls.target.set(-300,100,-300);

    function changeCameraPosition() {
        const nextSectionPositionTop = document.querySelector('.js-about-us').getBoundingClientRect().top / 2
        const windowHeight = window.innerHeight

        const currentPercent = (100 - 100 * nextSectionPositionTop / windowHeight) * 2
        camera.position.set( 200, 400 - (currentPercent / 100) * 250 , 200 );

        controls.target.set(-300,100,-300);
        if (window.innerWidth < 640) {
            controls.maxDistance = 850;
            controls.minDistance = 850;
        } else if (window.innerWidth < 1300 && window.innerWidth >= 640 ) {
            controls.maxDistance = 750;
            controls.minDistance = 750;
        } else {
            controls.maxDistance = 650;
            controls.minDistance = 650;
        }
        controls.update();
    }

    controls.update();

    const dudvMap = new THREE.TextureLoader().load( 'https://threejs.org/examples/textures/waterdudv.jpg', function () {

        animate();

    } );

    dudvMap.wrapS = dudvMap.wrapT = THREE.RepeatWrapping;

    const planeGeo = new THREE.PlaneGeometry( 600, 600 );

    const planeBottom = new THREE.Mesh( planeGeo, new THREE.MeshPhongMaterial( { color: '#cccccc' } ) );
    planeBottom.rotateX( - Math.PI / 2 );
    scene.add( planeBottom );

    const planeRight = new THREE.Mesh( planeGeo, new THREE.MeshPhongMaterial( { color: '#cccccc' } ) );
    planeRight.position.z = -300;
    planeRight.position.y = 300;
    planeRight.receiveShadow = true;
    scene.add( planeRight );

    const planeLeft = new THREE.Mesh( planeGeo, new THREE.MeshPhongMaterial( { color: '#cccccc' } ) );
    planeLeft.position.x = -300;
    planeLeft.position.y = 300;
    planeLeft.rotateY( Math.PI / 2 );
    planeLeft.receiveShadow = true;
    scene.add( planeLeft );

    // lights
    const mainLight = new THREE.PointLight( '#d6d7ff' );
    mainLight.position.set( 100, 100, 100 );
    mainLight.intensity = 0.2
    // mainLight.castShadow = true;
    scene.add( mainLight );

    const light = new THREE.SpotLight( '#d6d7ff', 1, 0 );
    light.castShadow = true; // default false
    light.penumbra = 0.5
    light.position.set( 60, 300, 60 );
    light.angle = Math.PI / 4
    light.distance = 1000;
    light.target.position.set(-300,0,-300);

    scene.add( light );

    const spotLightHelper = new THREE.SpotLightHelper( light );
    // scene.add( spotLightHelper );

    // Text
    const loader = new FontLoader();
    loader.load( 'https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', function (font) {
        const boldFont = Object.assign(font)
        boldFont.data = monitoricaBoldFont
        console.log(boldFont);

        window.addEventListener('scroll', changerightBoldTextLettersTextPosition)

        const color = '#edeaea';

        const matLite = new THREE.MeshBasicMaterial( {
            color: color,
            transparent: false,
            opacity: 1,
            side: THREE.DoubleSide
        } );

        const matDark = new THREE.MeshBasicMaterial( {
            color: "gray",
            transparent: false,
            opacity: 1,
            side: THREE.DoubleSide
        } );

        const rightBoldMessage = 'Interior Project';
        let leftBoldMessage = '\"Design & Build\"';
        leftBoldMessage = leftBoldMessage.split('').reverse()

        const rightRegularMessage = '-Solution For';
        let leftRegularMessage = 'Your One-Step';
        leftRegularMessage = leftRegularMessage.split('').reverse()

        const rightBoldTextLetters = []
        const rightBoldTextLetthersPositionY = []

        const leftBoldTextLetters = []
        const leftBoldTextLetthersPositionY = []

        const rightRegularTextLetters = []
        const rightRegularTextLettersPositionY = []

        const leftRegularTextLetters = []
        const leftRegularTextLettersPositionY = []

        for ( let i = 0; i < rightBoldMessage.length; i ++ ) {
            const shapes = boldFont.generateShapes( rightBoldMessage[i], 30)
            const geometry = new THREE.ShapeGeometry( shapes );
            geometry.computeBoundingBox();
            const xMid = - 0.5 * ( geometry.boundingBox.max.x - geometry.boundingBox.min.x );
            geometry.translate( xMid, 0, 0 );
            rightBoldTextLetters[i] = new THREE.Mesh( geometry, matLite );
            rightBoldTextLetters[i].position.x = -190 + i*17;
            rightBoldTextLetters[i].position.y = 150 + Math.floor(Math.random() * 8) * 10;
            rightBoldTextLetthersPositionY[i] = rightBoldTextLetters[i].position.y;
            rightBoldTextLetters[i].position.z = -200;
            rightBoldTextLetters[i].castShadow = true
            scene.add( rightBoldTextLetters[i] );
        }

        for ( let i = 0; i < leftBoldMessage.length; i ++ ) {
            const shapes = boldFont.generateShapes( leftBoldMessage[i], 30 );
            const geometry = new THREE.ShapeGeometry( shapes );
            geometry.computeBoundingBox();
            const xMid = - 0.5 * ( geometry.boundingBox.max.x - geometry.boundingBox.min.x );
            geometry.translate( xMid, 0, 0 );
            leftBoldTextLetters[i] = new THREE.Mesh( geometry, matLite );
            leftBoldTextLetters[i].position.x = -200
            leftBoldTextLetters[i].position.y = 150 + Math.floor(Math.random() * 8) * 10;
            leftBoldTextLetthersPositionY[i] = leftBoldTextLetters[i].position.y;
            leftBoldTextLetters[i].position.z = -190 + i*17;
            leftBoldTextLetters[i].rotateY(Math.PI / 2)
            leftBoldTextLetters[i].castShadow = true
            scene.add( leftBoldTextLetters[i] );
        }

        for ( let i = 0; i < rightRegularMessage.length; i ++ ) {
            const shapes = boldFont.generateShapes( rightRegularMessage[i], 30 );
            const geometry = new THREE.ShapeGeometry( shapes );
            geometry.computeBoundingBox();
            const xMid = - 0.5 * ( geometry.boundingBox.max.x - geometry.boundingBox.min.x );
            geometry.translate( xMid, 0, 0 );
            rightRegularTextLetters[i] = new THREE.Mesh( geometry, matLite );
            rightRegularTextLetters[i].position.x = -240 + i*17;
            rightRegularTextLetters[i].position.y = 220 + Math.floor(Math.random() * 8) * 10;
            rightRegularTextLettersPositionY[i] = rightRegularTextLetters[i].position.y;
            rightRegularTextLetters[i].position.z = -250;
            rightRegularTextLetters[i].castShadow = true
            scene.add( rightRegularTextLetters[i] );
        }

        for ( let i = 0; i < leftRegularMessage.length; i ++ ) {
            const shapes = boldFont.generateShapes( leftRegularMessage[i], 30 );
            const geometry = new THREE.ShapeGeometry( shapes );
            geometry.computeBoundingBox();
            const xMid = - 0.5 * ( geometry.boundingBox.max.x - geometry.boundingBox.min.x );
            geometry.translate( xMid, 0, 0 );
            leftRegularTextLetters[i] = new THREE.Mesh( geometry, matLite );
            leftRegularTextLetters[i].position.x = -250
            leftRegularTextLetters[i].position.y = 240 + Math.floor(Math.random() * 8) * 10;
            leftRegularTextLettersPositionY[i] = leftRegularTextLetters[i].position.y;
            leftRegularTextLetters[i].position.z = -220 + i*17;
            leftRegularTextLetters[i].rotateY(Math.PI / 2)
            leftRegularTextLetters[i].castShadow = true
            scene.add( leftRegularTextLetters[i] );
        }

        function changerightBoldTextLettersTextPosition() {
            const nextSectionPositionTop = document.querySelector('.js-about-us').getBoundingClientRect().top / 2
            const windowHeight = window.innerHeight

            if (nextSectionPositionTop < 0) {
                return
            }
            const currentPercent = (100 - 100 * nextSectionPositionTop / windowHeight) * 2
            for ( let i = 0; i < rightBoldMessage.length; i ++ ) {
                const currentPosition = rightBoldTextLetters[i].position.y
                rightBoldTextLetters[i].position.y = rightBoldTextLetthersPositionY[i] - (rightBoldTextLetthersPositionY[i] - 150) * currentPercent / 100
            }

            for ( let i = 0; i < leftBoldMessage.length; i ++ ) {
                const currentPosition = leftBoldTextLetters[i].position.y
                leftBoldTextLetters[i].position.y = leftBoldTextLetthersPositionY[i] - (leftBoldTextLetthersPositionY[i] - 150) * currentPercent / 100
            }

            for ( let i = 0; i < rightRegularMessage.length; i ++ ) {
                const currentPosition = rightRegularTextLetters[i].position.y
                rightRegularTextLetters[i].position.y = rightRegularTextLettersPositionY[i] - (rightRegularTextLettersPositionY[i] - 220) * currentPercent / 100
            }

            for ( let i = 0; i < leftRegularMessage.length; i ++ ) {
                const currentPosition = leftRegularTextLetters[i].position.y
                leftRegularTextLetters[i].position.y = leftRegularTextLettersPositionY[i] - (leftRegularTextLettersPositionY[i] - 220) * currentPercent / 100
            }
        }

    } );
}

function onWindowResize() {

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize( window.innerWidth, window.innerHeight );

}

function animate() {

    requestAnimationFrame( animate );

    const time = clock.getElapsedTime();

    renderer.render( scene, camera );

}
