let Projects = {
    init: function (conf) {
        this.config = conf
        this.bindEvents()
        this.letterMargins = []
        this.createJumbledText('TRANSFORMED LIVES')
        this.changeProjectsSampleBoxPosition()
    },

    bindEvents: function () {
        window.addEventListener('scroll', this.windowScroll)
    },

    changeJumbledLetterPosition: function () {
        const self = Projects
        const currentTopOfWord = self.config.jumbledTitle.getBoundingClientRect().top
        const heightOfWindow = window.innerHeight
        if (currentTopOfWord > heightOfWindow) {
            return
        }

        const lettersElements = document.querySelectorAll('.js-projects-jumbled-title span')
        lettersElements.forEach((el, index) => {
            const topMargin = self.letterMargins[index]
            const percent = ((currentTopOfWord - 150) * 100 / (heightOfWindow - 150))
            if (percent > 0) {
                const newTopMargin = (percent / 100) * topMargin
                el.style.marginTop = newTopMargin + 'px'
            }
        })
    },

    changeProjectsSampleBoxPosition: function () {
        const self = Projects
        const currentTopOfLeftBox = self.config.leftProjectSampleBox.getBoundingClientRect().top
        const currentTopOfRightBox = self.config.rightProjectSampleBox.getBoundingClientRect().top
        const heightOfWindow = window.innerHeight
        if (currentTopOfLeftBox > heightOfWindow) {
            return
        }
        const leftBoxTopMargin = 150
        const leftBoxPercent = 100 - ((currentTopOfLeftBox - 150) * 100 / (heightOfWindow - 150))
        if (leftBoxPercent <= 100 && leftBoxPercent >= 0) {
            const newTopMargin = (leftBoxPercent / 100) * leftBoxTopMargin
            self.config.leftProjectSampleBox.style.marginTop = newTopMargin + 'px'
        }

        const rightBoxTopMargin = -70
        const rightBoxPercent = 100 - ((currentTopOfRightBox - 150) * 100 / (heightOfWindow - 150))
        if (rightBoxPercent >= 0) {
            const newTopMargin = (rightBoxPercent / 100) * rightBoxTopMargin
            self.config.rightProjectSampleBox.style.marginTop = newTopMargin + 'px'
        }
    },

    windowScroll: function (e) {
        const self = Projects
        self.changeJumbledLetterPosition()
        self.changeProjectsSampleBoxPosition()
    },

    createJumbledText: function (text) {
        const self = Projects
        if (!text) {
            return
        }

        const textSplit = text.split('')
        const parentElement = document.createElement('div')
        parentElement.classList.add('inline-flex')
        textSplit.forEach((el, index) => {
            let randomNumber = (Math.random() < 0.5 ? -1 : 1) * (Math.floor(Math.random() * 100))
            if (randomNumber > 50) {
                randomNumber = 50
            } else if (randomNumber < -50) {
                randomNumber = -50
            }
            const letterElement = document.createElement('span')
            letterElement.innerText = el
            if (el === ' ') {
                letterElement.classList.add('px-2')
            }
            if (index === 0) {
                letterElement.style.marginTop = '-50px'
                self.letterMargins[0] = -50
            } else {
                self.letterMargins[index] = randomNumber
                letterElement.style.marginTop = randomNumber + 'px'
            }
            parentElement.appendChild(letterElement)
        })
        self.config.jumbledTitle.appendChild(parentElement)
    }
}

Projects.init({
    jumbledTitle: document.querySelector('.js-projects-jumbled-title'),
    leftProjectSampleBox: document.querySelector('.js-left-project-sample-box'),
    rightProjectSampleBox: document.querySelector('.js-right-project-sample-box'),
})
