let Comments = {
    init: function (conf) {
        this.config = conf
        this.bindEvents()
        this.letterMargins = []
        this.createJumbledText('LET’S TALK')
        this.changeCommentsBackgroundPosition()
    },

    bindEvents: function () {
        window.addEventListener('scroll', this.windowScroll)
    },

    changeJumbledLetterPosition: function () {
        const self = Comments
        const currentTopOfWord = self.config.jumbledTitle.getBoundingClientRect().top
        const heightOfWindow = window.innerHeight
        if (currentTopOfWord > heightOfWindow) {
            return
        }

        const lettersElements = document.querySelectorAll('.js-comments-jumbled-title span')
        lettersElements.forEach((el, index) => {
            const topMargin = self.letterMargins[index]
            const percent = ((currentTopOfWord - 150) * 100 / (heightOfWindow - 150))
            if (percent > 0) {
                const newTopMargin = (percent / 100) * topMargin
                el.style.marginTop = newTopMargin + 'px'
            }
        })
    },

    changeCommentsBackgroundPosition: function () {
        const self = Comments
        const currentTopOfBackground = self.config.backgroundBox.getBoundingClientRect().top
        const heightOfWindow = window.innerHeight
        if (currentTopOfBackground > heightOfWindow) {
            return
        }
        const backgroundBoxPosition = 300
        const backgroundBoxPercent = ((currentTopOfBackground - 150) * 100 / (heightOfWindow - 150))
        if (backgroundBoxPercent <= 100 && backgroundBoxPercent >= 0) {
            const newBackgroundBoxPosition = (backgroundBoxPercent / 100) * backgroundBoxPosition
            self.config.backgroundBox.style.backgroundPositionX = `${newBackgroundBoxPosition}px`
        }
    },

    windowScroll: function () {
        const self = Comments
        self.changeJumbledLetterPosition()
        self.changeCommentsBackgroundPosition()
    },

    createJumbledText: function (text) {
        const self = Comments
        if (!text) {
            return
        }

        const textSplit = text.split('')
        const parentElement = document.createElement('div')
        parentElement.classList.add('inline-flex')
        textSplit.forEach((el, index) => {
            let randomNumber = (Math.random() < 0.5 ? -1 : 1) * (Math.floor(Math.random() * 100))
            if (randomNumber > 50) {
                randomNumber = 50
            } else if (randomNumber < -50) {
                randomNumber = -50
            }
            const letterElement = document.createElement('span')
            letterElement.innerText = el
            if (el === ' ') {
                letterElement.classList.add('px-2')
            }
            if (index === 0) {
                letterElement.style.marginTop = '-50px'
                self.letterMargins[0] = -50
            } else {
                self.letterMargins[index] = randomNumber
                letterElement.style.marginTop = randomNumber + 'px'
            }
            parentElement.appendChild(letterElement)
        })
        self.config.jumbledTitle.appendChild(parentElement)
    }
}

Comments.init({
    jumbledTitle: document.querySelector('.js-comments-jumbled-title'),
    backgroundBox: document.querySelector('.js-comment-bg-box'),
})
